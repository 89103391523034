import { CONFIG_SETTINGS } from "../config";
import { isValidDocument } from "./files";
import jwt_decode from "jwt-decode";

export const getRatio = (ratio = '1/1') => {
    return {
      '4/3': 'calc(100% / 4 * 3)',
      '3/4': 'calc(100% / 3 * 4)',
      '6/4': 'calc(100% / 6 * 4)',
      '4/6': 'calc(100% / 4 * 6)',
      '16/9': 'calc(100% / 16 * 9)',
      '9/16': 'calc(100% / 9 * 16)',
      '21/9': 'calc(100% / 21 * 9)',
      '9/21': 'calc(100% / 9 * 21)',
      '1/1': '100%',
    }[ratio];
}

export const onGetFullImagePath = (src) => {
  const valid = isValidDocument(src)
  if(!valid) return null
  return `${CONFIG_SETTINGS.S3_SERVER_URL}${src}`
}

export const onGetFullname = (user) => {
  let result = ''
  if(!user) return result
  if(user?.first_name)  result+=user?.first_name
  if(user?.fathers_last_name)  result= `${result} ${user?.fathers_last_name}`
  if(user?.mothers_last_name)  result= `${result} ${user?.mothers_last_name}`
  return result
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export const string2Slug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñçěščřžýúůďťň·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuuncescrzyuudtn------";

  for (var i=0, l=from.length ; i<l ; i++)
  {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace('.', '-') // replace a dot by a dash 
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by a dash
    .replace(/-+/g, '-') // collapse dashes
    .replace( /\//g, '' ); // collapse all forward-slashes

  return str;
}

export const onGetUserTypeFromToken = () => {
    const token = localStorage.getItem('accessToken');
    let user_type = 0
    if(token){
      const decodedToken = jwt_decode(token)
      user_type = decodedToken?.user_type_id
    }
    return user_type
}

export const s2ab = (s) =>{ 
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  return buf;    
}

export const onGetJSONData = async(datain) => {
  let result = null
  try {
    result = JSON.parse(datain)
  } catch (error) {
    console.log(error) 
  }
  return result
}
