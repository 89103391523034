import React, { useCallback, useEffect, useState } from 'react';
import { Card, Box, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import CircularLoadingContainer from '../../../../components/loaders/CircularLoadingContainer';
import useAnalytics from '../../../../hooks/api/useAnalytics';
import Chart, {useChart} from '../../../../components/chart';
import { catalogs } from '../../../../utils/catalogs';

const IMCChart = ({
    start_date, end_date, client_id, client_location_id, client_program_id
}) => {

    const [loading, setLoading] = useState(false)
    const [analytics, setAnalytics] = useState({})
    const analyticsManager = useAnalytics()

    const initialize = useCallback(async() => {
        if(loading) return
        setLoading(true)
        try {
            const params = {start_date: start_date, end_date:end_date, 
                client_id: client_id > 0 ? client_id : undefined,
                client_location_id: client_location_id > 0 ? client_location_id : undefined,
                client_program_id: client_program_id > 0 ? client_program_id : undefined,
            }
            let result = []
            const _imc_analytics = await analyticsManager.actions.onGetUsersByIMCDiagnostic(params)

            for(let i=0; i<catalogs.imc_diagnostics.length; i++){
                let diagnosis = catalogs.imc_diagnostics[i]
                const elFound = _imc_analytics.find(el => el.imc_diagnostic_id === diagnosis.value)
                result.push(elFound ? elFound.user_count : 0)
            }

            ///let expectionObj =  _imc_analytics.find(el => el.objective_id === null)
            ///result.push(expectionObj ? expectionObj.user_count : 0)
            //console.log(result)



            setAnalytics(result)
        } catch (error) {
            console.log(error)
        }

        setLoading(false)
    }, [loading, start_date, end_date, client_id, client_location_id, client_program_id])

    useEffect(() => {
        initialize()
    }, [start_date, end_date, client_id, client_location_id, client_program_id])


    return ( 
        <Card
            sx={{px:2, py:2, minHeight:'100%'}}
        >
            <CircularLoadingContainer loading={loading} />
            <Typography variant='subtitle2'>Clasificación por diagnóstico IMC</Typography>
            <Box>
                <IMCPieChart series={analytics} />
            </Box>
        </Card>
     );
}
 
export default IMCChart;


const IMCPieChart = ({series}) => {

    let _options = [
        ...catalogs.imc_diagnostics,
        //{value:-1, label:'Sin clasificar', color:grey[300]}
    ]

    const chartOptions = useChart({
        labels: _options.map((el) => el.label),
        legend: {
            position:'bottom'
        },
        colors:_options.map((el) => el.color)
    })


    return(
    <Box
        sx={{
            width:'100%',
            maxWidth:500,
            margin:'auto'
        }}
        
    >
        <Chart
            type="pie" 
            series={series} 
            options={chartOptions}
        />
    </Box>
    )
}
