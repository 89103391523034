import React, { useRef } from 'react'
import { CircularProgress, Switch, TextField, Typography } from '@mui/material'
import { ruleValidation } from './customFunctions'
import { Stack } from '@mui/system'

const SwitchForm = props => {

    const {data, onChange} = props
    const {config, value, isValid, isVisited} = data

    const id = data?.config?.id

    const onInnerChange = event => {
      let temp = {...data};
      temp.value = temp.value === 1 ? 0 : 1;
      temp.isValid = ruleValidation(temp.value, temp.rules, temp.compareValue);
      temp.isVisited = true;
      onChange(temp);
    } 

    const isError = isVisited && !isValid
    let interConfig = {...config}
    if(!isError) interConfig.helperText = ''

    return(
        <div>
          <Stack direction='row' alignItems='center'>
            {interConfig?.label && <Typography variant='body2'>{interConfig?.label}</Typography>}
            <Switch checked={value===1} onChange={onInnerChange} />
          </Stack>
          
        </div>
    )
}

export default SwitchForm