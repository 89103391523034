import React, { useCallback, useEffect, useState } from 'react';
import { Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';
import CircularLoadingContainer from '../../../../components/loaders/CircularLoadingContainer';
import useAnalytics from '../../../../hooks/api/useAnalytics';
import { Box } from '@mui/system';
import { onGetIMCResult } from '../../../../utils/platform';
import useClientLocation from '../../../../hooks/api/useClientLocation';
import useClientProgram from '../../../../hooks/api/useClientProgram';
import CustomSmallSelect from '../../../../components/custom-input/CustomSmallSelect';

const header = [
    {id:1, label:''},
    {id:2, label:'Total Pacientes'},
    {id:3, label:'Peso Total Kg'},
    {id:4, label:'Promedio IMC'},
    {id:5, label:'Diagnóstico IMC'},
    {id:6, label:'Suma Circunferencia Cintura'},
    {id:7, label:'Suma Circunferencia Cadera'},
    {id:8, label:'Promedio ICC'},
    {id:9, label:'Kg grasa'},
    {id:10, label:'Kg músculo'},
]

const AnalyticsByBusinessTable = ({
    client, start_date, end_date, enable
}) => {

    const [loading, setLoading] = useState(false)
    const [analytics, setAnalytics] = useState([])
    const analyticsManager = useAnalytics()
    const locationManager = useClientLocation()
    const programManager = useClientProgram()
    const [totals, setTotals] = useState({})
    const [location, setLocation] = useState(-1)
    const [program, setProgram] = useState(-1)
    const [locations, setLocations] = useState([])
    const [programs, setPrograms] = useState([])

    const onGetAnalytics = useCallback(async() => {
        setLoading(true)
        let params = {start_date: start_date, end_date:end_date, client_id:client?.id_client}

        if(location > 0) params.client_location_id = location
        if(program > 0) params.client_program_id = program

        const _business_analytics = await analyticsManager.actions.onGetUsersByObjectiveExtended(params)
        //console.log(_business_analytics)
        setAnalytics(_business_analytics)

        let _totals = {
            user_count:0,
            total_weight:0,
            imc_average:0,
            circumference_waist_total:0,
            circumference_hips_total:0,
            icc_average:0,
            fat_mass_total:0,
            muscle_mass_total:0

        }

        for(let i=0; i<_business_analytics.length; i++){
            const temp = _business_analytics[i]
            _totals.user_count = _totals.user_count + parseFloat(temp?.user_count ?? 0)
            _totals.total_weight = _totals.total_weight + parseFloat(temp?.total_weight ?? 0)
            _totals.imc_average = _totals.imc_average + parseFloat(temp?.imc_average ?? 0)
            _totals.circumference_waist_total = _totals.circumference_waist_total + parseFloat(temp?.circumference_waist_total ?? 0)
            _totals.circumference_hips_total = _totals.circumference_hips_total + parseFloat(temp?.circumference_hips_total ?? 0)
            _totals.icc_average = _totals.icc_average + parseFloat(temp?.icc_average ?? 0)
            _totals.fat_mass_total = _totals.fat_mass_total + parseFloat(temp?.fat_mass_total ?? 0)
            _totals.muscle_mass_total = _totals.muscle_mass_total + parseFloat(temp?.muscle_mass_total ?? 0)
        }
        setTotals(_totals)
        setLoading(false)
    }, [loading, start_date, end_date, client, location, program])

    const initialize = useCallback(async() => {

        if(loading) return
        setLoading(true)
        try {
            
            let _analytics = []

            const _locations = await locationManager.actions.onGetAll({
                order:0, 
                order_by:JSON.stringify(['name']),
                filter: JSON.stringify({AND:[{field:'client_id', operator:'=', value:client?.id_client}]}
            )}) 
            //console.log(_locations)
            setLocations([{id_client_location:-1, name:'Todos'},..._locations?.data])

            const _programs = await programManager.actions.onGetAll({
                order:0, 
                order_by:JSON.stringify(['name']),
                filter: JSON.stringify({AND:[{field:'client_id', operator:'=', value:client?.id_client}]}
            )})

            //console.log(_programs)
            setPrograms([{id_client_program:-1, name:'Todos'},..._programs?.data])

            
            onGetAnalytics()
            

        } catch (error) {
            console.log(error)
        }

        setLoading(false)
    }, [])

    useEffect(() => {
       // console.log('Solicitar nueva data')
        onGetAnalytics()
    }, [start_date, end_date, location, program])

    useEffect(() => {
        if(enable) initialize()
    }, [enable, client])


    return ( 
        <div>
            <Box sx ={{px:1, py:2, borderRadius:1, mb:0.2, background:blueGrey[50]}}>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Typography variant='subtitle2' >{client?.name}</Typography>
                    </Grid>
                    <Grid item>
                        <CustomSmallSelect label='Sitio' value={location} onChange={(e) => setLocation(parseInt(e.target.value))}>
                            {locations.map((location) => (
                                <option key={location.id_client_location} value={location.id_client_location}>
                                    {location.name}
                                </option>
                            ))}
                        </CustomSmallSelect>
                    </Grid>
                    <Grid item>
                        <CustomSmallSelect label='Programa' value={program} onChange={(e) => setProgram(parseInt(e.target.value))}>
                            {programs.map((program) => (
                                <option key={program.id_client_program} value={program.id_client_program}>
                                    {program.name}
                                </option>
                            ))}
                        </CustomSmallSelect>
                    </Grid>
                </Grid>
            </Box>
            <Card sx={{width:'100%',mt:0, boxShadow:(theme) => theme.shadows[6]}}>
                <CircularLoadingContainer loading={loading} />
                
                <Table>
                    <TableHead>
                        <TableRow sx={{
                            background: (theme) => theme.palette.primary.main
                        }} >
                            {header.map(head => {
                                return(
                                    <TableCell key={`head-tb-${head.value}`} sx={{background:'transparent', px:1, py:1}}>
                                        <Typography variant='body2' color='white'>{head?.label}</Typography>
                                    </TableCell>
                                )
                            })}
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {analytics.map(data => {

                            const imcDiagnosis = onGetIMCResult(data?.imc_average ?? 0)

                            return(
                                <TableRow key={`${data?.objective_id}`}>
                                    <TableCell>
                                        <Typography variant='body2'>{data?.objective ?? 'Sin Clasificar'}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='subtitle2'>{data?.user_count ?? 0}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='subtitle2'>{data?.total_weight ?? 0}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='subtitle2'>{data?.imc_average ?? 0}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='subtitle2' noWrap>{imcDiagnosis}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='subtitle2'>{data?.circumference_waist_total ?? 0}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='subtitle2'>{data?.circumference_hips_total ?? 0}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='subtitle2'>{data?.icc_average ?? 0}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='subtitle2'>{data?.fat_mass_total ?? 0}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='subtitle2'>{data?.muscle_mass_total ?? 0}</Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow >
                            <TableCell>
                                <Typography variant='subtitle2'>{'Totales'}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='subtitle2' color='primary'>{totals?.user_count ?? 0}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='subtitle2' color='primary'>{totals?.total_weight ?? 0}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='subtitle2' color='primary'>{totals?.imc_average ?? 0}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='subtitle2' color='primary' noWrap>{''}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='subtitle2' color='primary'>{totals?.circumference_waist_total ?? 0}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='subtitle2' color='primary'>{totals?.circumference_hips_total ?? 0}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='subtitle2' color='primary'>{totals?.icc_average ?? 0}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='subtitle2' color='primary'>{totals?.fat_mass_total ?? 0}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='subtitle2' color='primary'>{totals?.muscle_mass_total ?? 0}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
        </div>
        
     );
}
 
export default AnalyticsByBusinessTable;

const AnalyticDataElement = ({
    label, 
    data=0,
    noline
}) => {
    return (
        <TableRow>
            <TableCell sx={{py:2, borderBottom:`1px solid ${noline ? 'transparent' : grey[200]}`}}>
                <Typography variant='body2' color='textSecondary'>{label}</Typography>
            </TableCell>
            <TableCell sx={{py:2, borderBottom:`1px solid ${noline ? 'transparent' : grey[200]}`}} >
                <Typography variant='subtitle2'>{data}</Typography>
            </TableCell>
        </TableRow>
    )
}