import React, { useCallback, useEffect, useState } from 'react';
import { Card, Box, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import CircularLoadingContainer from '../../../../components/loaders/CircularLoadingContainer';
import useAnalytics from '../../../../hooks/api/useAnalytics';
import Chart, {useChart} from '../../../../components/chart';
import { catalogs } from '../../../../utils/catalogs';

const PathologiesChart = ({
    start_date, end_date, client_id, client_location_id, client_program_id
}) => {

    const [loading, setLoading] = useState(false)
    const [analytics, setAnalytics] = useState({})
    const analyticsManager = useAnalytics()

    const initialize = useCallback(async() => {
        if(loading) return
        setLoading(true)
        try {
            const params = {start_date: start_date, end_date:end_date, 
                client_id: client_id > 0 ? client_id : undefined,
                client_location_id: client_location_id > 0 ? client_location_id : undefined,
                client_program_id: client_program_id > 0 ? client_program_id : undefined,
            }
            let result = []
            const _analytics = await analyticsManager.actions.onGetUsersByPathology(params)

            result = [
                {x:'Diabetes Mellitus', y: _analytics?.personal_diabetes ?? 0},
                {x:'Colesterol o triglicéridos altos', y: _analytics?.personal_high_cholesterol ?? 0},
                {x:'Hipertensión', y: _analytics?.personal_hypertension ?? 0},
                {x:'Enfermedades del corazón, infartos o embolias', y: _analytics?.personal_heart_diseases ?? 0},
                {x:'Cáncer', y: _analytics?.personal_cancer ?? 0},
                {x:'Problemas tiroideos', y: _analytics?.personal_thyroid_diseases ?? 0},
                {x:'Sobrepeso', y: _analytics?.personal_overweight ?? 0}, //
                {x:'Obesidad', y: _analytics?.personal_obesity ?? 0}, //
                {x:'Enfermedades gastrointestinales', y: _analytics?.personal_gastrointestinal_system_diseases ?? 0},
                {x:'Problemas psicológicos o psiquiátricos', y: _analytics?.personal_psychological_psychiatric_diseases ?? 0},
                {x:'SOP', y: _analytics?.personal_polycystic_ovary_syndrome ?? 0}, //
                {x:'Asma', y: _analytics?.personal_asthma ?? 0}, //
                {x:'Enf con inmunosupresores', y: _analytics?.personal_immunosuppressants_diseases ?? 0}, //
                {x:'Gota', y: _analytics?.personal_gout ?? 0}, // 
                {x:'IRC', y: _analytics?.personal_renal_insufficiency ?? 0}, //
                {x:'Pre Diabetes', y: _analytics?.personal_pre_diabetes ?? 0}, //
                {x:'Otras enfermedades', y: _analytics?.personal_other ?? 0},
            
            ]

            result = [{data:result}]

            setAnalytics(result)
        } catch (error) {
            console.log(error)
        }

        setLoading(false)
    }, [loading, start_date, end_date, client_id, client_location_id, client_program_id])

    useEffect(() => {
        initialize()
    }, [start_date, end_date, client_id, client_location_id, client_program_id])


    return ( 
        <Card
            sx={{px:2, py:2, minHeight:'100%'}}
        >
            <CircularLoadingContainer loading={loading} />
            <Typography variant='subtitle2'>Clasificación por grupo de riesgo</Typography>
            <Box>
                <PathologiesChartGraph series={analytics} />
            </Box>
        </Card>
     );
}
 
export default PathologiesChart;


const PathologiesChartGraph = ({series}) => {

    const chartOptions = useChart({
        //labels: _options.map((el) => el.label),
        //legend: {
        //    position:'bottom'
        //},
        //colors:_options.map((el) => el.color),
        plotOptions: {
            bar: {
              horizontal: true
            }
        },
    })

    ///console.log(chartOptions.colors)


    return(
    <Box
        sx={{
            width:'100%',
            //maxWidth:500,
            margin:'auto'
        }}
        
    >
        <Chart
            type="bar" 
            series={series} 
            options={chartOptions}
        />
    </Box>
    )
}
